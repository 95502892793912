import React, { useState } from "react";
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "./ReusableStyles";

// --- Image Imports ---
import MatrixScreenCopy from "../assets/matrixScreenCopy.png";
import TheArchetypeBrandTransformer from "../assets/TheArchetypeBrandTransformer.png";
import NewStorySpinnerLogo from "../assets/newStorySpinnerLogo.png";
import landscapeImg from "../assets/landscapeImg.jpg";
import cleaningHomecare from "../assets/cleaningHomecare.jpg";
import electricianHandyman from "../assets/electricianHandyman.jpg";
import mechanicRepairman from "../assets/mechanicRepairman.jpg";
import authorWriter from "../assets/authorWriter.jpg";
import artistCreators from "../assets/artistCreators.jpg";
import smallshopMarketplace from "../assets/smallshopMarketplace.jpg";
import showcaseRealEstate from "../assets/showcaseRealEstate.jpg";
import TermsofserviceMagnizying from "../assets/termsofserviceMagnizying.jpg";
import StrategicBusinessPlanner from "../assets/StrategicBusinessPlanner.png";
import RebelAdvisorIMG from "../assets/RebelAdvisorIMG.png";
import BusinessRebrandingTool from "../assets/BusinessRebrandingTool.png";
import SpectrumsAI from "../assets/spectrumsAI.png";
import tutorAI from "../assets/TutorAI.jpg";
import SpecializedAI from "../assets/SpecializedAI.jpg";
import CustomerServiceAI from "../assets/CustomerServiceAI.jpg";
import LawIMG from "../assets/law.png";

// --- Styled Components ---
const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  ${TitleStyles};
  .ReactSectionCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-items: center;
    margin: 4rem 0;
    .service {
      display: flex;
      flex-direction: column;
      height: 28rem;
      width: 100%;
      max-width: 300px;
      background: #0a0a0a;
      border: 1px solid #44fd47;
      border-radius: 8px;
      overflow: hidden;
      padding: 1rem;
      transition: box-shadow 0.3s ease;
      &:hover {
        box-shadow: 0 0 15px rgba(68, 253, 71, 0.5);
      }
      .image {
        height: 18rem;
        width: 100%;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        ${imageZoomEffect};
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          filter: brightness(60%);
        }
        p {
          position: absolute;
          bottom: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 0.9rem;
          font-weight: 500;
          letter-spacing: 0.1rem;
          color: #ffffff;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.7);
          font-family: "Orbitron", sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .ReactSectionCards {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
  }

  @media screen and (max-width: 690px) {
    .ReactSectionCards {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      justify-items: center;
      .service {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
`;

const CardInfo = styled.div`
  color: #44fd47;
  font-family: "Orbitron", sans-serif;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  padding: 0.5rem 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.5rem;
  height: 3rem;
`;

const StyledButton = styled.a.attrs(({ as }) => ({
  as: as || "a",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 44px;
  white-space: nowrap;
  padding: 6px 14px;
  font-family: "Orbitron", sans-serif;
  color: #44ff46;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    background: #44ff46;
    color: #010606;
  }
`;

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1; /* Ensure banner is above image */
`;

const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "#ff4b4b"};
  color: ${(props) => props.textColor || "#fff"};
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10; /* Ensure banner is above image */
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  padding: 2rem 0;
  font-family: "Orbitron", sans-serif;
  font-weight: 100;
  color: #45fe47;
  letter-spacing: 3px;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    width: 350px;
  }
`;

// --- Data ---
const data = [
  {
    image: SpectrumsAI,
    name: " AI-enhanced personalized autism support",
    link: "https://github.com/jrq3rq/SpectrumGuide",
    hasBanner: true,
    bannerText: "AI Service",
    githubOnly: true,
  },
  {
    image: LawIMG,
    name: "AI legal aid",
    desc:
      "Document summaries, research, task tracking—paralegal-focused, scalable.",
    link: "https://github.com/jrq3rq/AI-chrome-ext",
    link2: false,
    hasBanner: true,
    bannerText: "Browser Extension",
    githubOnly: true,
  },
  {
    image: TheArchetypeBrandTransformer,
    name: " AI-Enhanced Branding and Marketing Solutions",
    link: "https://chat.openai.com/g/g-SXTcCxFtV-archetype-brand-transformer",
    hasBanner: true,
    bannerText: "Custom GPT",
    githubOnly: false,
  },
  {
    image: NewStorySpinnerLogo,
    name: " Remixing Stories, Inspiring New Creations",
    link: "https://chat.openai.com/g/g-7T3hhDJO7-storyspinner",
    hasBanner: true,
    bannerText: "Custom GPT",
    githubOnly: false,
  },
  {
    image: RebelAdvisorIMG,
    name: "Rebel Advisor",
    link: "https://chat.openai.com/g/g-KjtcrDj33-rebel-advisor",
    hasBanner: true,
    bannerText: "Custom GPT",
    githubOnly: false,
  },
  {
    image: BusinessRebrandingTool,
    name: " Business Rebranding Tool",
    link: "https://chat.openai.com/g/g-np3Dj7gOE-business-rebranding-tool",
    hasBanner: true,
    bannerText: "Custom GPT",
    githubOnly: false,
  },
  {
    image: StrategicBusinessPlanner,
    name: "Strategic Business Partner",
    link: "https://chat.openai.com/g/g-3aqW0lmMT-strategic-business-partner",
    hasBanner: true,
    bannerText: "Custom GPT",
    githubOnly: false,
  },
  {
    image: MatrixScreenCopy,
    name: "Automated Invoice Generator",
    desc: "Streamline Invoice Creation",
    link: false,
    link2: false,
    hasBanner: true,
    bannerText: "SMBs",
    githubOnly: false,
    hideButtons: true,
  },
  {
    image: MatrixScreenCopy,
    name: "Order Fulfillment Tracker",
    desc: "Track Orders with Ease",
    link: false,
    link2: false,
    hasBanner: true,
    bannerText: "SMBs",
    githubOnly: false,
    hideButtons: true,
  },
  {
    image: MatrixScreenCopy,
    name: "Inventory Alert System",
    desc: "Get Proactive Inventory Notifications",
    link: false,
    link2: false,
    hasBanner: true,
    bannerText: "SMBs",
    githubOnly: false,
    hideButtons: true,
  },
  {
    image: SpecializedAI,
    name: "Specialized Assistant",
    desc: "Unlocking Potential with Expert AI Guidance.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: smallshopMarketplace,
    name: "AI Chatbots for Small businesses",
    desc: "Drive more traffic and generate more sales.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: tutorAI,
    name: "Coaching Assistant",
    desc: "Revolutionize education with AI tech.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: CustomerServiceAI,
    name: "Customer Service Assistant",
    desc: "Enhance customer support with 24/7 AI chatbot.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: landscapeImg,
    name: "Landscapers & Lawn care",
    desc: "Promote your products or services.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: cleaningHomecare,
    name: "Professional Cleaning Services",
    desc: "Tell your customers how to contact you.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: electricianHandyman,
    name: "Handymen & Electricians",
    desc: "Provide better service to your customers.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: mechanicRepairman,
    name: "Automotive Professionals",
    desc: "Make more money and provide better one-on-one service.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: authorWriter,
    name: "Book Authors & Illustrators",
    desc: "Increasing your online influence and credibility",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: artistCreators,
    name: "Visual Artists & Creators",
    desc: "Show you are serious about your art.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
  {
    image: showcaseRealEstate,
    name: "Real Estate Agents",
    desc: "Save more on marketing and look more professional.",
    link: false,
    link2: false,
    hasBanner: false,
    githubOnly: false,
  },
];

// --- Component ---
export default function ReactSectionCards() {
  const [toggledState, setToggledState] = useState(data.map(() => true));

  const toggleState = (index) => {
    const newState = [...toggledState];
    newState[index] = !newState[index];
    setToggledState(newState);
  };

  const openLinkInNewWindow = (url) => {
    if (url) {
      const newWindow = window.open(url, "_blank");
      newWindow.document.body.style.backgroundColor = "black";
    }
  };

  return (
    <Section id="ReactSectionCards">
      <div className="ReactSectionCards">
        {data.map((service, index) => {
          const isToggled = toggledState[index];

          return (
            <div className="service" key={index}>
              <div className="image">
                {service.hasBanner ? (
                  <BannerContainer>
                    <Banner
                      backgroundColor={
                        service.bannerText === "SMBs"
                          ? "#44ff46"
                          : service.bannerText === "Custom GPT"
                          ? "#32CD32"
                          : service.bannerText === "AI Service"
                          ? "#00d9ff"
                          : service.bannerText === "Browser Extension"
                          ? "#ff4b4b"
                          : "#ff4b4b"
                      }
                      textColor={
                        service.bannerText === "SMBs"
                          ? "#000000"
                          : service.bannerText === "Custom GPT"
                          ? "#ffffff"
                          : service.bannerText === "AI Service"
                          ? "#000000"
                          : service.bannerText === "Browser Extension"
                          ? "#ffffff"
                          : "#ffffff"
                      }
                      data-banner-text={service.bannerText}
                    >
                      {service.bannerText || "Browser Extension"}
                    </Banner>
                    <img src={service.image} alt="" />
                  </BannerContainer>
                ) : (
                  <img src={service.image} alt="" />
                )}
                <p>{service.desc}</p>
              </div>
              <CardInfo>{service.name}</CardInfo>
              <ButtonContainer>
                {!service.hideButtons && (
                  <>
                    {service.githubOnly ? (
                      <StyledButton
                        onClick={() => openLinkInNewWindow(service.link)}
                      >
                        GitHub
                      </StyledButton>
                    ) : (service.hasBanner &&
                        service.bannerText === "Custom GPT" &&
                        index < 7) ||
                      service.name === " Business Rebranding Tool" ||
                      service.name === "Strategic Business Partner" ? (
                      <StyledButton
                        onClick={() => openLinkInNewWindow(service.link)}
                      >
                        Try Out
                      </StyledButton>
                    ) : (
                      <>
                        <StyledButton
                          onClick={() => openLinkInNewWindow(service.link)}
                        >
                          Preview
                        </StyledButton>
                        <StyledButton
                          as="div"
                          onClick={() => openLinkInNewWindow(service.link2)}
                        >
                          Download
                        </StyledButton>
                      </>
                    )}
                  </>
                )}
              </ButtonContainer>
            </div>
          );
        })}
      </div>
    </Section>
  );
}
