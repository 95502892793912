// import ideas from "../../images/ideas.svg";
import builder from "../../images/builder.svg";
import landing_page from "../../images/landing_page.svg";
// import design from "../../images/design.svg";
// import design2 from "../../images/design2.svg";
// import freelancer from "../../images/freelancer.svg";
// import thinking from "../../images/thinking.svg";
// import codeThinking from "../../images/code-thinking.svg";
// import voice from "../../images/voice.svg";
// import voice2 from "../../images/voice2.svg";
// import profile from "../../images/profile.svg";
import undraw_new_ideas from "../../images/undraw_new_ideas.svg";

export const homeObjTwo = {
  id: "react",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: "Progressive Web Apps (PWAs) & More",
  topLine: "Templates",
  // topLine: "web +",
  // headline: "Discover the potential of personalized software",
  headline: "Innovative Solutions",
  // headline: "GPTs, PWAs, and Automation Scripts",
  // headline: "Landing pages for small businesses",
  description:
    // "Take full ownership and control of your vision with these lightweight app templates. Industry-specific builds are also available.    ",
    // "Take back control of your business or service with one of our free industry-specific web app templates. ",
    // "Get your business back on track with our free web app templates! ",
    // "Get your business online in no time with our easy-to-use landing page templates! ",
    // "Get your business online and in the hands of your consumers in no time with our easy-to-use landing page templates!  We also build Minimal Viable Products (MVPs) as a service.",
    // " Whether you need a simple tool or a complex platform, we have the skills and experience to deliver a solution that meets your needs and budget.",
    // "We build custom web apps for small businesses. Our team creates solutions that drive growth and improve efficiency.",
    // "Experience the power of a customizable applications - get started with our free templates!",
    // "Discover the potential of personalized software - try our free templates today!",
    // "Utilize our progressive web app templates, custom GPTs, and customizable scripts for task automation, enabling you to easily build tools that will enhance business efficiency.",
    "Leverage AI-powered PWA templates, custom GPTs, and automation scripts for efficient, scalable tools across autism support, legal aid, marketing, and more.",
  buttonLabel: "Explore Now",
  // buttonLabel: "Free Templates ",
  imgStart: true,
  img: builder,
  alt: "Templates",
  dark: false,
  primary: false,
  darkText: true,
};
