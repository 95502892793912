import voice2 from "../../images/voice2.svg";
import virtual_assistant from "../../images/virtual_assistant.svg";
import svgTwelveZordonBG from "../../images/svgTwelveZordonBG.svg";
import TwelveColorsBarLine from "../../images/12ColorsBarLine.png";
import Updated12jungianNew from "../../images/Updated12jungianNew.gif";
import Jungian_noletters from "../../images/12_Jungian_noletters.gif";

export const homeObjThree = {
  id: "dapps",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: "NFTs & Interaction Design ",
  // topLine: "Tool + NFTs",
  // topLine: "  12 Jungian",
  // topLine: "Jungian XII",
  // topLine: "NFTs",
  topLine: "Character Builder",
  // topLine: "AI",
  // topLine: "CARL-XII",

  // headline: "Build a virtual identity to represent your business",
  // headline: "Create your own voice-first business",
  // headline: "Build your own virtual assistant",
  // headline: "Virtual assistant marbles",
  // headline: "Virtual assistant character builder",
  // headline: "Interactive character creation tool",
  // headline: "Interactive character Builder",
  // headline: "Interactive character creations",
  // headline: "Make better characters!",
  // headline: "Collectible AGI Creations",
  // headline: " Interactive Character Builder ",
  // headline: " Explore your inner archetypes ",
  // headline: "Showcase your inner archetypes",
  // headline: "Amplify your knowledge with Carl-XII",
  // headline: "Immersive Character-Creation Tool",
  // headline: "Transform Your Space with AI-Powered Companions",
  headline: "Elevate Spaces with AI-Crafted Companions",
  // headline: "Transform your personal space with AI-powered companions",
  // headline: "Transform your personal space with Carl-XII",
  // headline: "Transform your personal space with AI-powered collectibles",
  // "Revolutionize Your Personal Space with Our Collectables Creator Tool",
  description:
    // "Use Jungian archetypes to create better human-machine interactions.",
    // "Learn how you can build better human-machine interactions by using Jungian archetypes.",
    // "Creating non-fungible virtual assistant characters using analytical psychology archetypes.",
    // "Utilizing analytical psychology archetypes to create better characters. Create your interactive non-fungible token.",
    // "Get to know your customers on a deeper level and create unforgettable characters with our analytical psychology archetypes.",
    // "If you're not sure what your business is missing, our non-fungible tokenized virtual assistants are here to help!",
    // "A friend for life, or your business.",
    // "If you can't figure out what your business is missing, our NFT virtual assistants are here to help!",
    // "If you're not sure what your business is missing, our NFT virtual assistants are here to help! Using analytical psychology, they'll help you create better characters that will serve as companionship or improve your business.",
    // "Get the insights you need to create unforgettable characters for your business, with our non-fungible token virtual assistants. If you can think it, we can token it.",
    // "Awaken everything around you using Jungian psychology character magic. Make your business persona pop with virtual characters that are one of a kind!",
    // "Awaken a new world of interactive characters that are one of a kind using Jungian magic. Tool for creating and integrating virtual characters.",
    // "Discover a new world of unique, interactive collectibles. Tool for creating and integrating Jungian archetypes. ",
    // "Make your personal space come alive using the magic of Jungian Archetypes. ",
    // "Make your personal space come alive using Jungian magic. ",
    // "Bring your personal space to life with our collectables creator! ",
    // "Experience the potential of our collectables creator and see how it can bring your personal space to life – demo stage now available. ",
    // "  Unlock the Power of AI-Human Connections through Analytical Psychology. Concept Stage Now Accessible.",
    // "  Create Authentic Relationships and Enhance Your Understanding of Human Behavior with Innovative Archetypal Technology. Concept Stage Now Accessible.",
    // "Create authentic relationships using cutting-edge technology with archetypal symbols and patterns that everyone can understand and relate to. Concept prototype now accessible.",
    "Create vibrant, relatable characters with AI-driven Jungian archetypes for personal enjoyment, evolving into immersive museum experiences—join the interactive prototype now!",
  // "  Create authentic relationships with cutting-edge technology that utilizes archetypal symbols and patterns that everyone can understand and relate to. Concept Prototype Now Accessible.",
  // "  Create authentic relationships with cutting-edge technology that utilizes archetypes to enhance human behavior. Concept Prototype Now Accessible.",
  // "  Bring people closer together with the help of technology, using symbols and patterns that everyone can understand and relate to, creating strong sense of unity and togetherness. Concept Prototype Now Accessible.",
  // buttonLabel: "Get started",
  // buttonLabel: "Explore Concepts",
  // buttonLabel: "Prototype",
  buttonLabel: "Learn more",
  // buttonLabel: "Version 0.1.0-alpha",
  // buttonLabel: "Carl-XII 0.1.0-alpha",
  // buttonLabel: "v0.1.0-alpha",
  // buttonLabel: "Design Your Toy",
  buttonLabel2: "Learn more",
  // buttonLabel2: "Overview",
  buttonLabel3: "Latest Version 0.2.0-alpha",
  // buttonLabel3: "Carl-XII 0.2.0-alpha",
  // buttonLabel3: "v0.2.0-alpha",
  // buttonLabel: "Showcase demo",
  // buttonLabel: "Concept demo",
  imgStart: false,
  img: svgTwelveZordonBG,
  alt: "Mint page",
  dark: true,
  primary: true,
  darkText: false,
};
