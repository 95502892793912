import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../images/Logo/logo-black.svg";

const Navbar = () => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 738);
  const { pathname } = useLocation();
  const sidebarRef = useRef(null);

  // Handle background color transition on scroll
  const changeNav = () => {
    console.log("Scroll Y:", window.scrollY); // Debug scroll position
    setScrollNav(window.scrollY > 0);
  };

  // Handle window resize for breakpoint
  useEffect(() => {
    const handleResize = () => {
      console.log("Window Width:", window.innerWidth); // Debug breakpoint
      setIsMobile(window.innerWidth < 738);
    };
    window.addEventListener("scroll", changeNav);
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on mount
    return () => {
      window.removeEventListener("scroll", changeNav);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle click outside to close sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        console.log("Clicked outside sidebar, closing..."); // Debug click outside
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleSidebar = () => {
    console.log("Toggling Sidebar, isOpen:", !isOpen); // Debug toggle
    setIsOpen(!isOpen);
  };

  const isHome = pathname === "/";

  return (
    <>
      <IconContext.Provider value={{ color: "#FFFFFF" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo as={LinkR} to="/" onClick={() => scroll.scrollToTop()}>
              <Logo className="logo" />
            </NavLogo>
            {isHome && (
              <NavLinksWrapper>
                <NavLink
                  to="react"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                >
                  Templates
                </NavLink>
                <NavLink
                  to="dapps"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                >
                  AI
                </NavLink>
                <NavLink
                  to="subscriptions"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                >
                  Agents
                </NavLink>
                <NavLink
                  to="Xarticles"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                >
                  Articles
                </NavLink>
              </NavLinksWrapper>
            )}
            <CalendlyButton
              as={LinkR}
              to="//calendly.com/studio-voice2fly/30min"
              target="_blank"
            >
              Let's Talk!
            </CalendlyButton>
            {isHome && isMobile && !isOpen && (
              <HamburgerIcon onClick={toggleSidebar}>
                <FaBars />
              </HamburgerIcon>
            )}
          </NavbarContainer>
        </Nav>
        {isOpen && isHome && isMobile && (
          <Overlay onClick={toggleSidebar} /> // Overlay to capture clicks outside sidebar
        )}
        {isHome && isMobile && (
          <Sidebar ref={sidebarRef} isOpen={isOpen}>
            <SidebarHeader onClick={toggleSidebar}>
              <CloseButton>
                <FaTimes />
              </CloseButton>
            </SidebarHeader>
            <SidebarContent>
              <SidebarLinks>
                <SidebarLink
                  to="react"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                  onClick={toggleSidebar}
                >
                  Templates
                </SidebarLink>
                <SidebarLink
                  to="dapps"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                  onClick={toggleSidebar}
                >
                  AI
                </SidebarLink>
                <SidebarLink
                  to="subscriptions"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                  onClick={toggleSidebar}
                >
                  Agents
                </SidebarLink>
                <SidebarLink
                  to="Xarticles"
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-80}
                  activeClass="active"
                  onClick={toggleSidebar}
                >
                  Articles
                </SidebarLink>
                <SidebarButton
                  as={LinkR}
                  to="//calendly.com/studio-voice2fly/30min"
                  target="_blank"
                  onClick={toggleSidebar}
                >
                  Let's Talk!
                </SidebarButton>
              </SidebarLinks>
            </SidebarContent>
          </Sidebar>
        )}
        {!isHome && (
          <HomeButton as={LinkR} to="/" onClick={() => scroll.scrollToTop()}>
            Home
          </HomeButton>
        )}
      </IconContext.Provider>
    </>
  );
};

export default Navbar;

// Styled Components
const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#010606" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: background 0.3s ease;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
  position: relative; /* Ensure proper stacking context */
  background: ${({ scrollNav }) =>
    scrollNav ? "#010606" : "transparent"}; /* Ensure background consistency */
`;

const NavLogo = styled(LinkR)`
  color: #ffffff;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  z-index: 11; /* Above other elements */
`;

const NavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 738px) {
    display: none;
  }
`;

const NavLink = styled(ScrollLink)`
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0 1rem 0.5rem; /* Added bottom padding for space */
  position: relative; /* For positioning the underline */
  transition: color 0.3s ease;

  &:hover {
    color: #44ff46;
  }

  &.active {
    &::after {
      content: "";
      position: absolute;
      bottom: -5px; /* Move line lower by increasing negative offset */
      left: 0;
      width: 100%;
      height: 4px; /* 4px underline */
      background: #44ff46;
      transition: bottom 0.3s ease; /* Smooth transition for line movement */
    }
  }
`;

const CalendlyButton = styled(LinkR)`
  border-radius: 50px;
  background: #44ff46;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background: #fff;
    color: #010606;
    box-shadow: 0 4px 12px rgba(68, 255, 70, 0.3);
  }

  @media screen and (max-width: 738px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 1.8rem;
  cursor: pointer;
  z-index: 20; /* Above sidebar */
  color: #ffffff;
  transition: color 0.3s ease;

  &:hover {
    color: #44ff46;
  }

  @media screen and (min-width: 738px) {
    display: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 10; /* Below sidebar but above other content */
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: #010606;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.5);
  z-index: 15; /* Below hamburger icon but above overlay */
  padding: 2rem;
  transition: transform 0.4s ease-in-out; /* Smooth slide effect */
  transform: translateX(100%); /* Start off-screen */

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: translateX(0); /* Slide in when open */
  `}
`;

const SidebarHeader = styled.div`
  position: absolute;
  top: 20px;
  left: 20px; /* Position at top-left */
  padding: 10px;
  cursor: pointer;
`;

const CloseButton = styled.div`
  font-size: 1.8rem;
  color: #ffffff;
  transition: color 0.3s ease;

  &:hover {
    color: #44ff46;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SidebarLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

const SidebarLink = styled(ScrollLink)`
  color: #ffffff;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #44ff46;
  }

  &.active {
    border-bottom: 4px solid #44ff46;
  }
`;

const SidebarButton = styled(LinkR)`
  border-radius: 50px;
  background: #44ff46;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: #fff;
    color: #010606;
    box-shadow: 0 4px 12px rgba(68, 255, 70, 0.3);
  }
`;

const HomeButton = styled(LinkR)`
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 50px;
  background: #44ff46;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 15;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: #fff;
    color: #010606;
    box-shadow: 0 4px 12px rgba(68, 255, 70, 0.3);
  }
`;
