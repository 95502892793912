import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { HomeButton } from "./RecapAILearnMore";
import Timer from "../components/Timer";

// Animation for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PrivacyPolicyContainer = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  padding: 100px 80px 40px;
  background-color: #f8f9fa;
  color: #333;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial,
    sans-serif;
  line-height: 1.8;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 80px 40px 20px;
  }

  @media screen and (max-width: 450px) {
    padding: 80px 20px 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 2em;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Paragraph = styled.p`
  margin-bottom: 1.5em;
  font-size: 16px;
  color: #444;
`;

const Policy = styled.div`
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial,
    sans-serif;
  padding: 20px;
`;

const MainHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #222;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5em;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const Header = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #666;
  margin-bottom: 1em;
`;

const PHeader = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #555;
  margin: 40px 0 15px;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 2px solid #44ff46; /* Subtle gradient underline */
  display: inline-block;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const SubHeader = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: #666;
  margin-top: 30px;
`;

const List = styled.ul`
  margin-left: 20px;
  font-size: 15px;
  line-height: 1.7;
  color: #777;
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;
`;

const Link = styled.a`
  color: #0077cc;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;

  &:hover {
    color: #005fa3;
    text-decoration: underline;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #44ff46;
  color: #010606;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  z-index: 100;

  &:hover {
    background-color: #3de03f;
  }

  @media screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
`;

const specifiedDate = "2024-01-17";

const PrivacyPolicy = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  // Show "Back to Top" button when scrolled down
  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <HomeButton to="/">Home</HomeButton>
      <PrivacyPolicyContainer>
        <header>
          <MainHeader>Archetype API</MainHeader>
          <Header>Privacy Policy</Header>
          <Timer date={specifiedDate} />
        </header>
        <main>
          <Section>
            <Paragraph>
              <Policy>
                <p>
                  Welcome to the{" "}
                  <Link
                    href="https://github.com/jrq3rq/ArchetypeAPI"
                    target="_blank"
                    aria-label="Link to ArchetypeAPI GitHub"
                  >
                    ArchetypeAPI
                  </Link>
                  . We are committed to protecting your privacy and ensuring you
                  have a positive experience on our Archetype Data Gateway. This
                  Privacy Policy outlines our practices regarding the
                  collection, use, and disclosure of information through{" "}
                  <Link
                    href="https://chat.openai.com/gpts"
                    target="_blank"
                    aria-label="Link to ChatGPT"
                  >
                    ChatGPT
                  </Link>{" "}
                  and the{" "}
                  <Link
                    href="https://openai.com/blog/introducing-the-gpt-store"
                    target="_blank"
                    aria-label="Link to GPT Store"
                  >
                    GPT Store
                  </Link>
                  .
                </p>
                <PHeader>Information We Collect</PHeader>
                <List>
                  <ListItem>
                    <b>User-Provided Information:</b> Currently, our API is
                    designed exclusively for GET requests and does not require
                    or collect any personal information from its users. This
                    includes the absence of data collection such as names, email
                    addresses, or any other personally identifiable information.
                  </ListItem>
                  <ListItem>
                    <b>Usage Data:</b> We do not collect usage data such as IP
                    addresses, access times, or request types. Our API's sole
                    function is to provide archetype data without tracking user
                    activity or data.
                  </ListItem>
                </List>
                <PHeader>How We Use Your Information</PHeader>
                <List>
                  <ListItem>
                    Our API is built to serve archetype data through GET
                    requests without collecting or using any personal
                    information from our users.
                  </ListItem>
                  <ListItem>
                    We aim to maintain the highest standards of privacy and data
                    security in line with industry best practices.
                  </ListItem>
                </List>
                <PHeader>Sharing of Information</PHeader>
                <List>
                  <ListItem>
                    Since we do not collect personal information, there is no
                    sharing of such data with third parties.
                  </ListItem>
                  <ListItem>
                    Any future changes to this policy, especially concerning
                    data collection and sharing, will be communicated explicitly
                    and will only be implemented with clear user consent.
                  </ListItem>
                </List>
                <PHeader>Data Security</PHeader>
                <List>
                  <ListItem>
                    We are committed to protecting our API and its users from
                    unauthorized access, alteration, disclosure, or destruction
                    of any data we might collect in the future.
                  </ListItem>
                  <ListItem>
                    Our security measures are designed to ensure the integrity
                    and confidentiality of all data.
                  </ListItem>
                </List>
                <PHeader>International Data Transfers</PHeader>
                <List>
                  <ListItem>
                    As of now, there are no international data transfers to be
                    concerned about, given our policy of not collecting personal
                    data.
                  </ListItem>
                </List>
                <PHeader>Your Rights</PHeader>
                <List>
                  <ListItem>
                    Although we currently do not collect personal data, we
                    recognize the importance of user rights in data privacy
                    matters. Should our policy change, we will ensure mechanisms
                    for accessing, updating, or deleting personal information.
                  </ListItem>
                </List>
                <PHeader>Changes to This Privacy Policy</PHeader>
                <List>
                  <ListItem>
                    This Privacy Policy may be updated over time to reflect
                    changes in our practices or for other operational, legal, or
                    regulatory reasons. We will notify you of any significant
                    changes by updating the policy on our website.
                  </ListItem>
                </List>
                <PHeader>Contact Us</PHeader>
                <List>
                  <ListItem>
                    To address any inquiries or concerns you may have about our
                    Privacy Policy, we warmly invite you to schedule a
                    consultation with our team. For your convenience, please use
                    the following Calendly link to{" "}
                    <Link
                      href="https://calendly.com/studio-voice2fly/30min"
                      target="_blank"
                      aria-label="Arrange a Meeting with Studio Voice2Fly"
                    >
                      'Arrange a Meeting'
                    </Link>
                    , allowing you to select a time that best suits your
                    schedule for our appointment.
                  </ListItem>
                </List>
              </Policy>
            </Paragraph>
          </Section>
        </main>
        <BackToTopButton visible={showBackToTop} onClick={scrollToTop}>
          ↑
        </BackToTopButton>
      </PrivacyPolicyContainer>
    </>
  );
};

export default PrivacyPolicy;
