import React, { useState } from "react";
import styled from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import {
  FaInstagram,
  FaGithub,
  FaUser,
  FaEthereum,
  FaYinYang,
} from "react-icons/fa";
import { MdFingerprint } from "react-icons/md";
import { CgInfinity } from "react-icons/cg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import HuggingFaceLogo from "../assets/hf-logo.png"; // Import the logo
import Slider from "../Slider/Slider";

// Styled Components
const PissyPants = styled.img.attrs({
  src: "../assets/PissyPants.png", // Adjust path if needed
  alt: "PissyPants Logo",
})`
  width: 75px;
  height: 75px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  margin-top: 50px;
`;

const StyledIcon = styled(FaYinYang)`
  margin: 0px 20px 0px 20px;
  color: #44fd47;
  font-size: 1rem;
  @media screen and (max-width: 820px) {
  }
`;

const HuggingFaceIcon = styled.img.attrs({
  src: HuggingFaceLogo,
  alt: "Hugging Face",
})`
  width: 32px;
  height: 32px;
  margin-right: 5px;
`;

const IconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const LabelOverIcon = styled.span`
  position: absolute;
  top: -10px; /* Adjust to position above the icon */
  left: 50%;
  transform: translateX(-50%) rotate(15deg); /* Center and rotate 15 degrees */
  font-size: 9px;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: ${(props) => props.backgroundColor || "#3ddf3f"};
  color: ${(props) => props.textColor || "#000000"};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const FooterLinkLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3ddf3f;
    transition: 0.3s ease-out;
  }
`;

export const FooterConatiner = styled.footer`
  background-color: transparent;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3ddf3f;
    transition: 0.3s ease-out;
  }
`;

export const FooterLinkButton = styled.button`
  color: #fff;
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3ddf3f;
    transition: 0.3s ease-out;
  }
`;

export const FooterLinkNoLink = styled.div`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3ddf3f;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const WebsiteRights = styled.small`
  color: transparent;
  margin-bottom: 16px;
  display: flex;
  margin-top: 10px;
  @media screen and (max-width: 820px) {
    margin-top: 0px;
    font-size: 0.75rem;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const CopyrightSymbl = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 40px;
  font-size: 0.6rem;
`;

const StrikethroughLink = styled(FooterLinkNoLink)`
  text-decoration: line-through;
  text-decoration-color: #44fd47;
  text-decoration-thickness: 2px;
  font-size: 14px;
`;

const LabelWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 14px;
`;

const Label = styled.span`
  position: absolute;
  top: -10px;
  right: -20px;
  font-size: 9px;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
  transform: rotate(15deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const HeaderLabelWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const HeaderLabel = styled.span`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 9px;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "#3ddf3f"};
  color: ${(props) => props.textColor || "#000000"};
`;

const labelTypes = {
  new: { text: "NEW", color: "#3ddf3f", textColor: "#000000" },
  construction: {
    text: "UNDER CONSTRUCTION",
    color: "#ffa500",
    textColor: "#000000",
  },
  progress: { text: "IN PROGRESS", color: "transparent", textColor: "#ffffff" },
  comingSoon: { text: "COMING SOON", color: "#E70E7E", textColor: "#ffffff" },
  beta: { text: "BETA", color: "transparent", textColor: "#ffffff" },
  live: { text: "LIVE", color: "transparent", textColor: "#ffffff" },
  alpha: { text: "ALPHA", color: "#3ddf3f", textColor: "#000000" },
  alpha2: { text: "ALPHA", color: "transparent", textColor: "#ffffff" },
};

const LabeledHeader = ({ labelType, children }) => {
  const label = labelTypes[labelType];
  return (
    <HeaderLabelWrapper>
      <FooterLinkTitle>{children}</FooterLinkTitle>
      {label && (
        <HeaderLabel backgroundColor={label.color} textColor={label.textColor}>
          {label.text}
        </HeaderLabel>
      )}
    </HeaderLabelWrapper>
  );
};

const LinkContent = styled.div`
  display: flex;
  align-items: center; /* Vertically center text and icon */
`;

const LabeledLink = ({ to, href, labelType, children, ...props }) => {
  const label = labelTypes[labelType];
  const LinkComponent = href ? FooterLinkLink : FooterLink;
  const linkProps = href ? { href, ...props } : { to, ...props };

  return (
    <LabelWrapper>
      <LinkComponent {...linkProps}>
        <LinkContent>
          {href === "//huggingface.co/spaces/jrq3rq/AI-Archetype-Finder" && (
            <IconWrapper>
              <HuggingFaceIcon />
              {label && (
                <LabelOverIcon
                  backgroundColor={label.color}
                  textColor={label.textColor}
                >
                  {label.text}
                </LabelOverIcon>
              )}
            </IconWrapper>
          )}
          {children}
        </LinkContent>
      </LinkComponent>
      {/* Keep the original Label for other links */}
      {href !== "//huggingface.co/spaces/jrq3rq/AI-Archetype-Finder" &&
        label && (
          <Label
            style={{ backgroundColor: label.color, color: label.textColor }}
          >
            {label.text}
          </Label>
        )}
    </LabelWrapper>
  );
};

// Footer Component
const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const [buttonPopup, setButtonPopup] = useState(false);

  return (
    <>
      <FooterConatiner>
        <Slider trigger={buttonPopup} setTrigger={setButtonPopup} />
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>About</FooterLinkTitle>
                <FooterLinkButton onClick={() => setButtonPopup(true)}>
                  NFT Gallery
                </FooterLinkButton>
                <FooterLink to="/about">Our Process</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Products</FooterLinkTitle>
                <LabeledLink
                  href="//huggingface.co/spaces/jrq3rq/AI-Archetype-Finder"
                  target="_blank"
                  labelType="new"
                >
                  AI Archetype Finder
                </LabeledLink>
                <LabeledLink
                  href="//us-central1-archetype-builder-api.cloudfunctions.net/api/archetypes"
                  target="_blank"
                  labelType="new"
                >
                  ArchetypeAPI
                </LabeledLink>
                <LabeledLink
                  href="//archetype-academy-nexus.web.app/assessment"
                  target="_blank"
                  labelType="beta"
                >
                  MindPulse-150
                </LabeledLink>
                <LabeledLink
                  href="//jamesrrs-projects-app.web.app/character-creation-tool"
                  target="_blank"
                >
                  Carl-XII
                </LabeledLink>
                <LabeledLink
                  href="//archetype-academy-nexus.web.app/archedex-demo"
                  target="_blank"
                  labelType="alpha"
                >
                  Archédex
                </LabeledLink>
                <LabeledLink
                  href="//archetype-academy-nexus.web.app/"
                  target="_blank"
                >
                  The Archetype Academy
                </LabeledLink>
                <LabeledLink to="/memorypal">MemoryPal</LabeledLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <LabeledHeader>GPTs</LabeledHeader>
                <LabeledLink
                  href="//chat.openai.com/g/g-7T3hhDJO7-storyspinner-gpt"
                  target="_blank"
                >
                  Story Spinner
                </LabeledLink>
                <LabeledLink
                  href="//chatgpt.com/g/g-KjtcrDj33-rebel-advisor"
                  target="_blank"
                >
                  Rebel Advisor
                </LabeledLink>
                <LabeledLink
                  href="//chat.openai.com/g/g-SXTcCxFtV-archetype-brand-transformer"
                  target="_blank"
                >
                  Archetype Brand Transformer
                </LabeledLink>
                <LabeledLink
                  href="//chatgpt.com/g/g-np3Dj7gOE-business-rebranding-tool"
                  target="_blank"
                >
                  Business Rebranding
                </LabeledLink>
                <LabeledLink
                  href="//chatgpt.com/g/g-3aqW0lmMT-strategic-business-partner"
                  target="_blank"
                >
                  Strategic Business Partner
                </LabeledLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Shop</FooterLinkTitle>
                <LabeledLink to="/futurestore">Toy Store</LabeledLink>
                <StrikethroughLink>Character Marbles</StrikethroughLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <LabeledHeader labelType="comingSoon">
                  Future Projects
                </LabeledHeader>
                <LabeledLink to="/scripts" labelType="new">
                  Short Films
                </LabeledLink>

                <StrikethroughLink>AI-Enhanced Museums </StrikethroughLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <WebsiteRights to="/" onClick={toggleHome}>
                Created with ❤️ by La Jota
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="//www.linkedin.com/company/studiovoice2fly/"
                  target="_blank"
                  aria-label="LinkedIn"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </SocialIconLink>
                <SocialIconLink
                  href="//www.twitter.com/studiovoice2fly"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </SocialIconLink>
                <SocialIconLink
                  href="//github.com/jrq3rq"
                  target="_blank"
                  aria-label="Github"
                >
                  <FontAwesomeIcon icon={faGithub} />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
          <CopyrightSymbl>
            <StyledIcon />
            Studio Voice2FLY © 2018 – <CgInfinity size={20} />
          </CopyrightSymbl>
        </FooterWrap>
      </FooterConatiner>
    </>
  );
};

export default Footer;
